<template>
  <div>
    <v-form>
      <p class="mb-1">
        Which of the images below depict footwear/apparel that is appropriate to be worn in the
        chemistry laboratory?
      </p>

      <v-simple-table max-width="327px">
        <tr v-for="i in 4" :key="i">
          <td>
            <v-checkbox
              v-model="inputs.studentAnswers"
              :value="optionsShuffled[i - 1].value"
              max-width="30px"
              class="my-4"
            />
          </td>
          <td>
            <v-img :src="optionsShuffled[i - 1].img" max-width="297px" />
          </td>
        </tr>
        <tr>
          <td>
            <v-checkbox v-model="inputs.studentAnswers" value="none" />
          </td>
          <td>None of the above</td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import {seededShuffle} from '@/courses/utils/seededShuffle';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'ChemMcM1A03_PL3Q1',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {img: '/img/assignments/footwear1.png', value: '1'},
        {img: '/img/assignments/footwear2.png', value: '2'},
        {img: '/img/assignments/footwear4.png', value: '4'},
        {img: '/img/assignments/footwear5.png', value: '5'},
        {img: '/img/assignments/footwear6.png', value: '6'},
        {img: '/img/assignments/footwear7.png', value: '7'},
        {img: '/img/assignments/footwear8.png', value: '8'},
        {img: '/img/assignments/footwear9.png', value: '9'},
        {img: '/img/assignments/footwear10.png', value: '10'},
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    seed() {
      return this.versionNumber.value;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
